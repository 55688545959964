export const title = 'Banery';
export const mainHeader = 'Banery';

export const content = [
  {
    id: 'banery-reklamowe',
    article: `## Banery <span class="orange">reklamowe</span>
      \n Promowanie zarówno usługi, jak i towaru bądź produktu jest w dzisiejszych czasach koniecznością. Spowodowane jest to przede wszystkim dużą konkurencją na rynkach w wielu obszarach. Istnieje kilka metod, dzięki którym firmy mogą wyróżnić się na tle przedsiębiorstw tożsamych. Jedną z najbardziej sprawdzonych i efektywnych metod jest stosowanie
      reklam w postaci fizycznej. W naszej wysokiej klasy drukarni wykonujemy między innymi wielkoformatowe banery reklamowe, które dzielą się na kilka wariantów, takich jak:\n * standard,\n * powlekany,\n * odblaskowy,\n * siatka mesh.
      \n W zależności od wybranego wariantu, banery wielkoformatowe będą różnić się stylem wykonania, rozmiarem czy wykorzystanym materiałem. I choć każdy z dostępnych rodzajów przeznaczony jest do efektywnej reklamy, tak poszczególne z nich lepiej sprawdzą się w konkretnych lokalizacjach.    
    `,
  },
  {
    id: 'kompleksowe-banery',
    article: `## Kompleksowe <span class="orange">banery</span>
    \n Co istotne, nasza działalność nie ogranicza się wyłącznie do drukowania gotowych wielkoformatowych rozwiązań. Jako jedno z niewielu przedsiębiorstw na rynku oferujemy również kompleksowe projektowanie grafik. Cała czynność wykonywana jest przez doświadczonych specjalistów, którzy są ekspertami w dziedzinie marketingu. Wedle podanych
    przez Ciebie wytycznych przygotujemy kilka projektów, które z pewnością wprowadzą Cię w zachwyt. Co istotne, na życzenie klienta realizujemy również montaż przygotowanych i wydrukowanych elementów. Dzięki temu masz gwarancję, że ulokowany w odpowiednim miejscu element został umieszczony w odpowiedni sposób i pod odpowiednim kątem.
    \n Podstawowym świadczeniem naszej działalności jest jednak drukowanie banerów, które odbywa się według ustalonych wcześniej wytycznych. Zarówno proporcje, jak i wykorzystany materiał dobierany jest według Twoich preferencji. Jeżeli nie wiesz na jaki typ wielkoformatowego baneru reklamowego powinieneś się zdecydować, chętnie Ci pomożemy.
    Skontaktuj się z nami, a nasi eksperci odpowiedzą na rodzące się w Twojej głowie pytania czy wątpliwości. Po przeprowadzonym dialogu pomożemy Ci wybrać rozwiązanie, które będzie dla Twojej sytuacji optymalne.    
    `,
  },
];
